@import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 60px #dbdbdb;
  border-radius: 10rem;
}

::-webkit-scrollbar-thumb {
  background-color: #3a7735;
  outline: none;
  border-radius: 10rem;
}

/* @font-face {
  font-family: 'sap-icons';
  src:  url('public/fonts/BusinessSuite-Icons/BusinessSuite-Icons/IconHTMLTemplate_InAppSymbols_1.077/fonts/BusinessSuiteInAppSymbols.woff') format('woff')
} */


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html,
body,
#app,
#app>div {
  height: 100%;
  min-height: 100% !important;

}

#root {
  height: 100%;
  min-height: 100% !important;
}

.circular._access-icon {
  position: fixed !important;
  bottom: 140px !important;
  background-color: #4054b2 !important;
  width: 117px !important;
  height: 32px !important;
  gap: 10px !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  visibility: visible !important;
  direction: rtl !important;
  border-radius: 0px 0px 4px 4px !important;
  right: -43px !important;
  left: auto !important;
  transform: rotate(90deg) !important;
  cursor: pointer !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 28px !important;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  transition: width 0.4s ease 0s, border-radius 0.4s ease 0s !important;
  z-index: 20000000 !important;
  top: auto !important;
}